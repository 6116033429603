exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-photo-js": () => import("./../../../src/templates/photo.js" /* webpackChunkName: "component---src-templates-photo-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

